<template>
    <v-row justify="space-around">
        <v-col cols="auto">
            <v-dialog v-model="dialog" width="1900" height="80vh">
                <div style="background-color: var(--v-gray3-base)">
                    <v-toolbar color="primary" dark class="text-center">
                        <div class="header-grid text-center">
                            <div></div>
                            <h4 class="d-flex justify-center align-center">{{ $t('callback.callback') }}</h4>
                            <div class="d-flex justify-end align-center">
                                <v-tooltip bottom>
                                    <template #activator="{ on, attrs }">
                                        <v-chip
                                            v-bind="attrs"
                                            color="gray3"
                                            class="mx-2 ml-4 mr-0 gray-text-2"
                                            v-on="on"
                                        >
                                            {{ $route.params.caseId }}
                                        </v-chip>
                                    </template>
                                    <span>{{ $t('callback.caseNumber') }}</span>
                                </v-tooltip>
                                <v-btn icon @click="dialog = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </div>
                        </div>
                    </v-toolbar>
                    <v-card-text>
                        <br />
                        <br />
                        <v-row>
                            <v-col sm="8">
                                <div class="pa-2 softShadow rounded bg-white h-200">
                                    <v-row>
                                        <v-col cols="9">
                                            <v-card-text>
                                                <v-row>
                                                    <v-col>
                                                        <b>{{ $t('callback.socSecNumber') }}:</b>
                                                        <v-tooltip bottom>
                                                            <template #activator="{ on, attrs }">
                                                                <span v-bind="attrs" v-on="on">
                                                                    <button
                                                                        v-if="clientNowOnCard.socialSecurityNumber"
                                                                        class="mx-1"
                                                                        @click="copy"
                                                                    >
                                                                        <v-icon small>mdi-content-copy</v-icon>
                                                                    </button>
                                                                </span>
                                                            </template>
                                                            <span>{{ $t('callback.copySocSecNumber') }}</span>
                                                        </v-tooltip>
                                                        <input
                                                            ref="personnummerCopy"
                                                            readonly
                                                            :value="
                                                                clientNowOnCard.socialSecurityNumber ||
                                                                $t('callback.unknown')
                                                            "
                                                            @focus="$event.target.select()"
                                                        />
                                                    </v-col>
                                                    <v-col>
                                                        <b>{{ $t('global.name') }}:</b>
                                                        {{ clientNowOnCard.name || $t('callback.unknown') }}
                                                    </v-col>
                                                    <v-col>
                                                        <b>{{ $t('callback.address') }}:</b>
                                                        {{ clientNowOnCard.adress || $t('callback.unknown') }}
                                                        {{ clientNowOnCard.post || '' }}
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <b>{{ $t('callback.email') }}:</b>
                                                        {{ clientNowOnCard.primaryEmail || $t('callback.unknown') }}
                                                    </v-col>
                                                    <v-col>
                                                        <b>{{ $t('callback.phonenumber') }}:</b>
                                                        {{ phonenumber || $t('callback.unknown') }}
                                                    </v-col>
                                                    <v-col>
                                                        <b>{{ $t('callback.lang') }}:</b>
                                                        <img
                                                            style="
                                                                border-radius: 2px;
                                                                vertical-align: middle;
                                                                width: 16px;
                                                                height: auto;
                                                            "
                                                            :src="getFlag(caseATM.case.language)"
                                                            :alt="getLanguage(caseATM.case.language)"
                                                        />
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <b>{{ $t('callback.cNumber') }}:</b>
                                                        {{ clientNowOnCard.clientId || $t('callback.unknown') }}
                                                    </v-col>
                                                    <v-col>
                                                        <b>{{ $t('callback.cPerson') }}:</b>
                                                        {{ clientNowOnCard.contactContract || $t('callback.unknown') }}
                                                    </v-col>
                                                    <v-col>
                                                        <b>{{ $t('callback.queue') }}:</b>
                                                        {{ caseATM.case.queue || $t('callback.unknown') }}
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-card-text class="d-flex flex-column align-start">
                                                {{ clientNowOnCard.socialSecurityNumber }}
                                                <IntegrationLinks
                                                    :socialSecurity="clientNowOnCard.socialSecurityNumber"
                                                />
                                                <CustomIntegrationLinks
                                                    :key="customKey"
                                                    :clientOnCase="clientNowOnCard"
                                                    @runLink="() => customKey++"
                                                />
                                                <BankIdButton
                                                    :key="caseInfo.caseId"
                                                    :caseId="caseInfo.caseId"
                                                    :clientId="clientNowOnCard.clientId"
                                                    :ssn="clientNowOnCard.socialSecurityNumber"
                                                />
                                            </v-card-text>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                            <v-col sm="4">
                                <div class="pa-2 softShadow rounded bg-white h-200">
                                    <v-card-text class="pa-2">
                                        <b>{{ $t('callback.postPCallback') }}</b>
                                    </v-card-text>
                                    <v-card-text v-if="startTime.length > 0" class="pa-2">
                                        <v-row>
                                            <v-col sm="12">
                                                <DatetimePicker
                                                    v-model="startTime"
                                                    :text-field-props="textFieldProps"
                                                    :date-picker-props="dateProps"
                                                    :time-picker-props="timeProps"
                                                    :label="$t('callback.change')"
                                                    :clearText="$t('callback.clear')"
                                                    @input="postponeBooking"
                                                >
                                                    <template slot="dateIcon">
                                                        <v-icon>mdi-calendar</v-icon>
                                                    </template>
                                                    <template slot="timeIcon">
                                                        <v-icon>mdi-clock-outline</v-icon>
                                                    </template>
                                                </DatetimePicker>
                                                <v-row>
                                                    <v-col sm="6">
                                                        <v-btn
                                                            color="secondary"
                                                            class="ma-1 softShadow"
                                                            small
                                                            @click="postponeBooking(10)"
                                                        >
                                                            + 10 min
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col sm="6">
                                                        <v-btn
                                                            class="ma-1 softShadow"
                                                            small
                                                            color="secondary"
                                                            @click="postponeBooking(20)"
                                                        >
                                                            + 20 min
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col />
                                        </v-row>
                                    </v-card-text>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <div class="pa-2 softShadow rounded bg-white h-362">
                                    <v-card-text>Logg</v-card-text>
                                    <div class="scroll-wrapper" style="overflow-y: auto; height: 245px">
                                        <div v-if="comments.length === 0" class="justify-center">
                                            <center>
                                                <br />
                                                <v-img
                                                    src="@/assets/General/empty_view.svg"
                                                    class="justify-center"
                                                    width="12rem"
                                                />
                                                <!-- Ingen data här ännu. -->
                                                {{ $t('callback.noData') }}
                                            </center>
                                        </div>
                                        <v-card-text v-for="(item, i) in comments" :key="i">
                                            <v-card
                                                class="pa-3 softShadow"
                                                outlined
                                                color="rgb(243, 243, 243)"
                                                style="
                                                    border-color: rgb(243, 243, 243);
                                                    background-color: rgb(233, 233, 233);
                                                "
                                                rounded
                                            >
                                                <v-card-text style="font-weight: 450" class="pa-0">
                                                    {{ item.from || item.agentName || '' }} -
                                                    {{ getDay(item.dateTime) }}
                                                    {{
                                                        item.typeOfMessage == 'intern' ? '' : '- ' + item.typeOfMessage
                                                    }}
                                                </v-card-text>
                                                <v-card-text v-dompurify-html="item.comment" class="pa-0" />
                                            </v-card>
                                        </v-card-text>
                                    </div>
                                    <v-card-actions class="d-flex justify-start">
                                        <template v-for="(item, i) in communicationTypes">
                                            <div :key="i" :class="item.dialogId != 5 ? 'flex-shrink-1' : 'flex-grow-1'">
                                                <v-tooltip top>
                                                    <template #activator="{ on, attrs }">
                                                        <v-btn
                                                            color="primary"
                                                            icon
                                                            class="softShadow"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            @click="item.func()"
                                                        >
                                                            <v-icon :size="item.dialogId == 5 ? '30' : '24'">
                                                                {{ item.icon }}
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    {{ item.text }}
                                                </v-tooltip>
                                            </div>
                                        </template>
                                    </v-card-actions>
                                </div>
                            </v-col>
                            <v-col>
                                <div class="pa-2 softShadow rounded bg-white h-362">
                                    <v-card-text>{{ $t('callback.notes') }}</v-card-text>
                                    <v-card-text>
                                        <v-row>
                                            <v-col>
                                                <v-textarea
                                                    v-model="notes"
                                                    filled
                                                    placeholder="Skriv dina anteckningar här"
                                                    style="max-width: 660px !important"
                                                    outlined
                                                >
                                                    {{ $t('callback.content') }}
                                                </v-textarea>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <v-btn
                                                    class="softShadow"
                                                    :style="{ color: 'white' }"
                                                    :color="callbackState == 'Avklarad' ? 'red' : 'green'"
                                                    @click="saveCallback()"
                                                >
                                                    {{
                                                        callbackState == 'Avklarad'
                                                            ? $t('global.close')
                                                            : $t('global.btn.save')
                                                    }}
                                                </v-btn>
                                            </v-col>
                                            <v-col>
                                                <PopupMenu
                                                    :state="{ value: selectedUserID }"
                                                    closeOnSelect
                                                    :heading="$t('callback.filterUsers')"
                                                    searchAble
                                                    infinite
                                                    :fetchFunction="fetchUsers"
                                                    :filteredData="filteredData"
                                                    :loading="userLoading"
                                                    returnObject
                                                    width="auto"
                                                    style="flex-basis: 0"
                                                    @input="(v) => (caseUserModel = v)"
                                                    @infiniteSearch="(v) => search(v)"
                                                >
                                                    <template #button>
                                                        <v-btn
                                                            style="
                                                                height: 100%;
                                                                background-color: white;
                                                                min-width: 100%;
                                                                border: 1px solid var(--v-gray3-base);
                                                            "
                                                            class="pa-2 rounded d-flex justify-start align-center"
                                                            elevation="0"
                                                            :ripple="false"
                                                        >
                                                            <v-btn
                                                                fab
                                                                small
                                                                color="white"
                                                                elevation="0"
                                                                class="pa-0 mr-2 softShadow"
                                                                style="cursor: pointer"
                                                            >
                                                                <CommonAvatar
                                                                    :key="caseUserModel.UserID"
                                                                    :userId="caseUserModel.UserID"
                                                                />
                                                            </v-btn>

                                                            <span
                                                                class="text-truncate"
                                                                style="
                                                                    font-size: 14px;
                                                                    letter-spacing: 0px;
                                                                    font-weight: 400;
                                                                "
                                                            >
                                                                <span
                                                                    class="text-truncate"
                                                                    style="
                                                                        letter-spacing: 0px;
                                                                        font-weight: 400;
                                                                        text-transform: none;
                                                                    "
                                                                >
                                                                    {{ getProfileName }}
                                                                </span>
                                                            </span>
                                                        </v-btn>
                                                    </template>
                                                    <template #default="{ item }">
                                                        <div class="d-flex flex-row align-center">
                                                            <CommonAvatar
                                                                :key="item.UserID"
                                                                size="28"
                                                                :userId="item.UserID"
                                                                class="mr-2"
                                                            />
                                                            <span style="font-size: 12px">{{ item.Name }}</span>
                                                        </div>
                                                    </template>
                                                </PopupMenu>
                                            </v-col>
                                            <v-col>
                                                <v-select
                                                    v-model="callbackState"
                                                    dense
                                                    outlined
                                                    :label="$t('callback.selOutcome')"
                                                    :items="stateCallback"
                                                    item-text="name"
                                                    item-value="name"
                                                />
                                            </v-col>
                                            <v-col>
                                                <v-tooltip bottom>
                                                    <template #activator="{ on, attrs }">
                                                        <span v-bind="attrs" v-on="on">
                                                            <v-btn
                                                                icon
                                                                class="softShadow"
                                                                color="primary"
                                                                dense
                                                                @click="openMoveCase()"
                                                            >
                                                                <v-icon>mdi-briefcase-arrow-left-right</v-icon>
                                                            </v-btn>
                                                        </span>
                                                    </template>
                                                    <span>{{ $t('callback.moveCase') }}</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </div>
                            </v-col>
                            <v-col v-if="audioFile">
                                <v-card class="pa-2 softShadow" style="background-color: #f7f7f7" height="362px">
                                    <v-card-text>{{ $t('callback.customerMess') }}</v-card-text>
                                    <div class="d-flex flex-column justify-center">
                                        <WaveSurfer
                                            :src="audioFile"
                                            :styleProp="{ backgroundColor: '#f7f7f7' }"
                                            :options="{ progressColor: '#008ffb', waveColor: 'grey', responsive: true }"
                                        />
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn text @click="dialog = false">{{ $t('global.close') }}</v-btn>
                    </v-card-actions>
                </div>
                <CaseMovePopup
                    :key="caseInfo.caseId"
                    :caseId="caseInfo.caseId"
                    :moveCase="moveCase"
                    @close="closeMoveCase($event)"
                />
            </v-dialog>
        </v-col>
    </v-row>
</template>
<script>
    import { parseISO, format, addMinutes } from 'date-fns';
    import { mapGetters, mapState } from 'vuex';
    import { getDay } from '@/utils/DateFormatter';
    import { debouncer } from '@/utils';
    import { getFlag as getFlagSvg } from '@/utils/flags';
    import IntegrationLinks from '../IntegrationLinks/IntegrationLinks.vue';
    import DatetimePicker from 'vuetify-datetime-picker/src/components/DatetimePicker.vue';

    export default {
        components: {
            IntegrationLinks,
            PopupMenu: () => import('@/components/Cases/Navigation/PopupMenu.vue'),
            WaveSurfer: () => import('../../Admin/OpenHours/FileLibrarySection/WaveSurferVue.vue'),
            CaseMovePopup: () => import('../CaseMovePopup.vue'),
            CommonAvatar: () => import('@/components/Global/CommonAvatar.vue'),
            CustomIntegrationLinks: () => import('@/components/Cases/IntegrationLinks/CustomIntegrationLinks.vue'),
            BankIdButton: () => import('@/components/Cases/BankIdButton.vue'),
            DatetimePicker,
        },
        data() {
            return {
                moveCase: 0,
                customKey: 0,
                caseUserModel: '',
                textFieldProps: {
                    appendIcon: 'mdi-calender',
                },
                userLoading: false,
                dateProps: {
                    headerColor: '',
                    locale: 'sv',
                    'first-day-of-week': 1,
                },
                timeProps: {
                    format: '24hr',
                },
                comments: [],
                callbackState: 'Avklarad',
                startTime: '',
                audioFile: null,
                dialog: false,
                phonenumber: '',
                notifications: false,
                sound: true,
                widgets: false,
                numberView: [this.$t('callback.standardNumber')],
                selectedNumber: [this.$t('callback.standardNumber')],
                loaded: false,
                page: 1,
                stateCallback: [
                    { id: 1, name: 'Avklarad' },
                    { id: 2, name: 'Ring igen' },
                    { id: 3, name: 'Upptaget' },
                    { id: 4, name: 'Numret gick ej att nå' },
                    { id: 5, name: 'Fel person' },
                    { id: 6, name: 'Röstbrevlåda' },
                ],
                communicationTypes: [
                    {
                        icon: 'mdi-phone-outgoing',
                        text: this.$t('callback.startCall'),
                        dialogId: 5,
                        disabled: false,
                        func: () => this.makeCall(),
                    },
                    {
                        icon: 'mdi-chat-processing-outline',
                        text: this.$t('callback.sendSms'),
                        dialogId: 3,
                        disabled: false,
                        func: () => this.openSMS(),
                    },
                    {
                        icon: 'mdi-email-outline',
                        text: this.$t('callback.writeMail'),
                        dialogId: 1,
                        disabled: false,
                        func: () => this.openMail(),
                    },
                ],
                filteredData: [],
                searchInput: '',
                debouncedSearch: null,
                notes: '',
            };
        },

        computed: {
            ...mapGetters({
                caseATM: 'Cases/getSelectedCase',
                getCaseAlternativesValue: 'Cases/getCaseAlternativesValue',
                selectedCaseId: 'Cases/getSelectedCaseId',
                getCaseUserPersonNr: 'Cases/getCaseUserPersonNr',
            }),
            ...mapState({
                selectedCase: (state) => state.Cases.selectedCase,
                caseInfo: (state) => state.Cases.selectedCase.case,
                clientNowOnCard: (state) => state.Cases.clientNowOnCard.data,
                userSettings: (state) => state.System.userSettings,
            }),

            selectedUserID() {
                return this.selectedCase?.case.userId;
            },
            selectedCaseId: {
                get() {
                    return this.$store.state.Cases.selectedCaseId;
                },
                set(value) {
                    this.$store.dispatch('Cases/setSelectedCaseId', value);
                },
            },

            getProfileName() {
                return this.caseUserModel?.Name || this.caseATM?.case?.agentName;
            },
            loggedInUser() {
                return this.$store.state.Auth.userObject;
            },
            callbackCallNow() {
                return this.$store.state.Cases.callbackCallNow;
            },
        },
        watch: {
            clientNowOnCard: {
                immediate: true,
                deep: true,
                handler() {
                    this.init();
                },
            },
            caseATM(val) {
                this.comments = val.comments.reverse();
                this.loaded = true;
            },
            dialog(newValue) {
                // console.log('Dialog ===== ', newValue);
                if (!newValue) {
                    this.closeDialog();
                }
            },
            caseId: {
                immediate: true,
                handler() {
                    this.notes = localStorage.getItem('__callback__notes__' + this.caseId) || '';
                },
            },
            notes() {
                localStorage.setItem('__callback__notes__' + this.caseId, this.notes);
            },
        },
        methods: {
            getDay,
            parseISO,
            format,
            copy() {
                this.$refs.personnummerCopy.focus();
                document.execCommand('copy');

                this.$toasted.show(this.$t('callback.ssnCopied'), {
                    icon: 'thumb-up',
                    type: 'success',
                    duration: 5000,
                });
            },
            openMoveCase() {
                this.$store.dispatch('Cases/setMoveCase', 1);
            },
            closeMoveCase(e) {
                this.$store.dispatch('Cases/setMoveCase', e);
                this.dialog = false;
            },
            getFlag(flag) {
                return getFlagSvg(flag);
            },
            openSMS() {
                this.$store.dispatch('Cases/setCommunicationCreatorData', { type: 3 });
            },
            openMail() {
                this.$store.dispatch('Cases/setCommunicationCreatorData', { type: 1 });
            },
            async postponeBooking(time) {
                let newStartTime = '';
                if (Number.isInteger(time)) {
                    newStartTime = addMinutes(parseISO(this.startTime), time);
                } else {
                    newStartTime = new Date(time);
                }

                newStartTime = format(newStartTime, 'yyyy-MM-dd HH:mm');
                this.startTime = newStartTime;

                const updateSLA = {
                    Field: 'SLA',
                    Value: newStartTime,
                    CaseId: this.caseATM.case.caseId,
                };
                const newTimes = {
                    slaTime: updateSLA,
                    callbackId: Number(this.caseATM.case.msgId.replace('callback', '')),
                };
                await this.$store.dispatch('CallbackAdmin/postponeBooking', newTimes);
            },
            makeCall() {
                const obj = {
                    close: false,
                    comment: '<b>' + this.$t('callback.attempedCall') + ':</b> ' + this.phonenumber,
                    ext: 'true',
                    from: this.loggedInUser.userName,
                    userId: this.loggedInUser.userId,
                    RecieverNumber: this.phonenumber,
                    attachment: [{ name: '' }],
                    messageType: 'call',
                    CaseID: this.caseATM.case.caseId,
                };
                const number = '' + this.phonenumber;
                this.$store.dispatch('Cases/sendComment', obj);
                // let callToLink = 'callto:*' + this.caseATM.caseStart.CaseID + '#' + number;
                const callToLink = 'tel:' + number;
                window.open(callToLink, '_self');
            },
            async fetchUsers(page = 1) {
                const users = await this.$store.dispatch('Users/getUsersAndRoles', {
                    search: this.searchInput,
                    page,
                });

                return {
                    hasMore: users.hasMore,
                    data: users.data.map((user) => {
                        return {
                            ...user,
                            value: user.UserID,
                        };
                    }),
                };
            },
            async saveCallback() {
                if (this.callbackState === '') {
                    this.$toasted.show(this.$t('callback.attempedCall'), {
                        icon: 'alert',
                        type: 'error',
                        duration: 5000,
                    });
                    return;
                }
                let closeCase = false;

                const obj = {
                    close: closeCase,
                    comment:
                        '<b>' +
                        this.$t('global.status') +
                        ':</b> ' +
                        this.callbackState +
                        ' - <b>' +
                        this.$t('callback.doneBy') +
                        ':</b> ' +
                        this.loggedInUser.userName +
                        '<br/><b>' +
                        this.$t('callback.comment') +
                        ':</b> ' +
                        this.notes,
                    from: this.caseUserModel.Name || this.loggedInUser.userName,
                    userId: this.caseUserModel.UserID || this.loggedInUser.userId,
                    RecieverNumber: this.phonenumber,
                    attachment: [{ name: '' }],
                    messageType: 'intern',
                    result: this.callbackState,
                    ext: 0,
                    CaseID: this.caseATM.case.caseId,
                    internalType: this.caseATM.case.internalType,
                    callbackId: Number(this.caseATM.case.msgId.replace('callback', '')),
                };
                localStorage.removeItem('__callback__notes__' + this.caseId);
                if (this.callbackState === 'Avklarad') {
                    this.selectedCaseId = 0;
                    closeCase = true;
                }
                await this.$store
                    .dispatch('Cases/sendComment', obj)
                    .then(() => {
                        this.notes = '';
                        this.callbackState = this.$t('callback.accomplished');
                    })
                    .catch((error) => {
                        console.error('Error in saveCallback', error);
                        this.$toasted.show(this.$t('callback.errMess1'), {
                            icon: 'alert',
                            type: 'error',
                            duration: 5000,
                        });
                    });

                const endTime = format(addMinutes(new Date(this.startTime), 30), 'yyyy-MM-dd HH:mm');
                const attemptResultId = this.stateCallback.find((el) => el.name === this.callbackState);

                const attemptObj = [
                    Number(this.caseATM.case.msgId.replace('callback', '')),
                    {
                        attemptResultId: 1,
                        commentTypeId: attemptResultId?.id || 0,
                        startedOn: this.startTime,
                        endedOn: endTime,
                        comment: this.notes,
                    },
                ];
                await this.$store
                    .dispatch('Cases/attempt', attemptObj)
                    .then(() => {
                        this.notes = '';
                        this.callbackState = 'Avklarad';
                    })
                    .catch((error) => {
                        console.error('Error in saveCallback', error);
                        this.$toasted.show(this.$t('callback.errMess2'), {
                            icon: 'alert',
                            type: 'error',
                            duration: 5000,
                        });
                    });

                this.dialog = false;
                this.closeDialog();
            },
            search(v) {
                this.searchInput = v;
                this.debouncedSearch();
            },
            getLanguage(lang) {
                if (lang === 'se') {
                    return ' ' + this.$t('callback.swedish');
                }
                return ' ' + this.$t('callback.english');
            },
            closeDialog() {
                this.loaded = false;
                this.$store.dispatch('Cases/callbackCallNow', false);
                this.dialog = false;
                this.selectedCaseId = 0;
                this.$router.push('/cases');
            },
            async getData() {
                this.userLoading = true;
                const { data } = await this.fetchUsers(1);
                this.filteredData = data;
                this.userLoading = false;
            },
            async init() {
                this.debouncedSearch = debouncer(async () => {
                    await this.getData();
                }, 500);
                if (this.caseATM.case.msgId.includes('callback')) {
                    this.phonenumber = await this.$store.dispatch(
                        'CallbackAdmin/getPhonenumber',
                        Number(this.caseATM.case.msgId.replace('callback', ''))
                    );
                }
                // Following if-statement is fallback if case doesn't have a callback. Only occurs if callback is created before new callback.
                if (this.phonenumber?.length > 1) {
                    this.phonenumber = this.phonenumber.PhoneNumber;
                } else if (this.caseATM.case.msgId.includes('callback')) {
                    this.phonenumber = this.clientNowOnCard.primaryTelephone || this.caseATM.case.clientId;
                } else {
                    this.phonenumber = this.clientNowOnCard.primaryTelephone || this.clientNowOnCard.secondaryTelephone;
                }

                this.dialog = true;

                const { data } = await this.fetchUsers(1);
                this.filteredData = data;
                this.caseUserModel = this.filteredData.find((el) => el.UserID === this.selectedCase.case.userId);
                if (!this.caseUserModel) {
                    this.caseUserModel = {
                        UserId: this.caseATM.case.userId,
                        Name: this.caseATM.case.agentName,
                    };
                }

                this.startTime = format(new Date(this.caseATM.case.sla), 'yyyy-MM-dd HH:mm');
                if (this.caseATM.case.hasAttachment && this.caseATM.fileAttaches.length) {
                    const res = await this.$store.dispatch('Cases/getSoundComments', this.caseATM.fileAttaches[0].id);
                    const buff = Buffer.from(res, 'base64'); // create buffer from base64
                    const context = new AudioContext(); // decode it
                    this.audioFile = await context.decodeAudioData(
                        buff.buffer.slice(buff.byteOffset, buff.byteLength + buff.byteOffset)
                    );
                }

                if (this.callbackCallNow) {
                    this.makeCall();
                }
            },
        },
    };
</script>
<style lang="scss" scoped>
    .bg-white {
        background: white;
    }

    .h-200 {
        height: 200px;
    }

    .h-362 {
        height: 362px;
    }

    .gray-text-2 {
        color: var(--v-gray2-base) !important;
    }

    .header-grid {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
    }
</style>
<i18n lang="json">
{
    "en": {
        "callback": {
            "caseNumber": "Case number",
            "callback": "Callback",
            "socSecNumber": "SSN",
            "copySocSecNumber": "Copy SSN",
            "unknown": "Unknown",
            "address": "Address",
            "email": "E-mail",
            "phonenumber": "Phone number",
            "lang": "Languague",
            "cNumber": "Customer number",
            "cPerson": "Contact person",
            "postPCallback": "Postpone callback",
            "change": "Change",
            "clear": "Clear",
            "noData": "No data here yet.",
            "notes": "Notes",
            "content": "Content",
            "accomplished": "Accomplished",
            "selOutcome": "Select outcome",
            "moveCase": "Move case",
            "customerMess": "Message from customer",
            "standardNumber": "Standard Number",
            "callAgain": "Call again",
            "busy": "Busy",
            "inaccessible": "Inaccessible number",
            "wrongPerson": "Wrong person",
            "voiceMail": "Voicemail",
            "sendSms": "Send an SMS",
            "startCall": "Start a call",
            "writeMail": "Write a mail",
            "allUsers": "All users",
            "ssnCopied": "SSN copied",
            "attempedCall": "Attempted call to",
            "comment": "Comment",
            "doneBy": "Done by",
            "errMess1": "Failed to save, try again. Please contact support with this error code 1337 and this info",
            "errMess2": "Failed to save, try again. Please contact support with this error code 1338 and this info",
            "swedish": "Swedish",
            "english": "English",
            "noUser": "No user",
            "filterUsers": "Filter users",
            "queue": "Queue"
        }
    },
    "sv": {
        "callback": {
            "caseNumber": "Ärendenummer",
            "callback": "Återuppringning",
            "socSecNumber": "PersonNr",
            "copySocSecNumber": "Kopiera Personnummer",
            "unknown": "Okänd",
            "address": "Adress",
            "email": "E-post",
            "phonenumber": "Telefonnummer",
            "lang": "Språk",
            "cNumber": "Kundnummer",
            "cPerson": "Kontaktperson",
            "postPCallback": "Skjut upp återuppringning",
            "change": "Ändra",
            "clear": "Rensa",
            "noData": "Inget data här ännu.",
            "notes": "Anteckningar",
            "content": "Innehåll",
            "accomplished": "Avklarad",
            "selOutcome": "Välj utfall",
            "moveCase": "Flytta ärende",
            "customerMess": "Meddelande från kund",
            "standardNumber": "Standard Nummer",
            "callAgain": "Ring igen",
            "busy": "Upptaget",
            "inaccessible": "Numret gick ej att nå",
            "wrongPerson": "Fel person",
            "voiceMail": "Röstbrevlåda",
            "sendSms": "Skicka ett SMS",
            "startCall": "Starta samtal",
            "writeMail": "Skriv ett mejl",
            "allUsers": "Alla användare",
            "ssnCopied": "Personnummer kopierat",
            "attempedCall": "Samtalsförsök till",
            "comment": "Kommentar",
            "doneBy": "Utfört av",
            "errMess1": "Gick inte att spara, prova igen. Kontakta gärna supporten med denna felkod 1337 och denna info",
            "errMess2": "Gick inte att spara, prova igen. Kontakta gärna supporten med denna felkod 1338 och denna info",
            "swedish": "Svenska",
            "english": "Engelska",
            "noUser": "Ingen användare",
            "filterUsers": "Filtrera användare",
            "queue": "Kö"
        }
    }
}
</i18n>
