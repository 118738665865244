import DOMPurify from 'dompurify';
import Vue from 'vue';

const linkifyDirective = {
    bind(el, binding) {
        const shadow = el.attachShadow({ mode: 'closed' });

        const content = binding.value || '';
        const sanitizedContent = DOMPurify.sanitize(content, {
            ALLOWED_TAGS: ['a', 'span', 'div', 'p', 'br'],
            ALLOWED_ATTR: ['href', 'target', 'rel', 'class'],
        });

        const linkifiedContent = sanitizedContent.replace(
            /((https?:\/\/)[^\s]+)/g,
            '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>',
        );

        const div = document.createElement('div');
        div.innerHTML = linkifiedContent;

        div.addEventListener('click', (event) => {
            const link = event.target.closest('a');
            if (link) {
                event.preventDefault();
                el.dispatchEvent(
                    new CustomEvent('link-clicked', {
                        detail: { href: link.href },
                    }),
                );
            }
        });

        shadow.appendChild(div);
    },
};

Vue.directive('linkify-dom', linkifyDirective);

export default linkifyDirective;
