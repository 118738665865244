<template>
    <section v-if="showComments" class="comment-grid-container">
        <!-- Header -->
        <header class="comment-header">
            <section v-if="selectedCase && clientNowOnCard && selectedCase?.case?.msgId?.includes('callback')">
                <Callback />
            </section>
            <section>
                <section>
                    <HorizontalCaseMenu :readOnly="isReadOnly" />
                </section>
            </section>
            <section class="case-comment-header">
                <article class="case-comment-header-case-info">
                    <div>
                        <InternalType v-if="selectedCase?.case" :item="selectedCase?.case" />
                    </div>
                    <div class="dynamic-truncate-content">
                        <div class="text-truncate d-flex weightMedium bigText">
                            <EditableText
                                v-if="isChat || isCall"
                                :existingText="getCaseTopic"
                                fieldWidth="300px"
                                :textFieldClass="'titleField'"
                                fieldHeight="30px"
                                fontSize="bigText"
                                fontWeight="weightMedium"
                                iconSize="20px"
                                :iconColor="colors.gray2"
                                @saveText="(v) => updateTopic(v)"
                            />

                            <v-tooltip v-else bottom>
                                <template #activator="{ on }">
                                    <span class="text-truncate case-topic" v-on="on">{{ getCaseTopic }}</span>
                                </template>
                                {{ getCaseTopic }}
                            </v-tooltip>

                            <span
                                v-if="
                                    userSettings.cases.showLatestSubject.active &&
                                    selectedCase?.case?.internalType !== 'social'
                                "
                                class="grayText2"
                            >
                                &nbsp; {{ getRecentTopic() }}
                            </span>
                        </div>
                        <div style="max-width: 500px" class="smallText text-truncate">
                            <span class="grayText2">{{ getHeading() }}</span>
                            <span style="color: var(--v-primary-base)">{{ getClientHeading }}</span>
                        </div>
                    </div>
                </article>
                <CaseIdChips />
                <InternalSubTypeIntegrationImage
                    v-if="selectedCase.case.internalSubType === 'lime_locked'"
                    :integration="'lime'"
                />

                <article>
                    <AttachmentsMenu v-if="selectedCase.case.attachment" />
                </article>

                <section class="d-flex justify-end">
                    <v-menu offset-y :close-on-content-click="false">
                        <template #activator="{ on: menu, attrs }">
                            <v-tooltip bottom>
                                <template #activator="{ on: tooltip }">
                                    <v-btn
                                        v-bind="attrs"
                                        color="gray3"
                                        width="32"
                                        height="32"
                                        fab
                                        depressed
                                        v-on="{ ...tooltip, ...menu }"
                                    >
                                        <v-icon size="20">mdi-menu</v-icon>
                                    </v-btn>
                                </template>
                                {{ $t('caseComments.commentSettings') }}
                            </v-tooltip>
                        </template>

                        <v-list style="position: static">
                            <v-list-item class="settings-list-item">
                                <v-switch v-model="showSysNotif" dense />
                                <label>{{ $t('caseComments.showSystemNotif') }}</label>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </section>

                <section class="active-users">
                    <ActiveUsers />
                </section>

                <!-- Subcases, Search and Close -->
                <section class="search-close">
                    <SubcaseList v-if="isInSubCase" :selectedCase="selectedCase" />
                    <search-input
                        dense
                        outlined
                        rounded
                        :loading="loadingSearch"
                        :debounceTime="1000"
                        :value="commentSearch"
                        @input="handleSearch"
                    />

                    <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                            <v-btn
                                class="mx-1 c1-btn"
                                fab
                                elevation="0"
                                height="32"
                                width="32"
                                v-bind="attrs"
                                color="white"
                                v-on="on"
                                @click="closeCase()"
                            >
                                <v-icon size="20" class="grayText2">mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>
                            <span>{{ $t('caseComments.closeCase') }}</span>
                        </span>
                    </v-tooltip>
                </section>
            </section>
        </header>
        <div v-if="parentOrSubcase === 'subcase'" class="subcase-identifier">
            <div class="subcase-identifier-text">
                <v-icon class="icon-branch-style" color="white" size="15">mdi-source-branch</v-icon>
                {{ $t('caseComments.subcase') }}
            </div>
            <div class="subcase-identifier-navigation">
                <SubcaseList v-if="!isInSubCase" :selectedCase="selectedCase" />
            </div>
        </div>
        <!-- Start of comments -->
        <section class="comment-content-outer">
            <section v-if="!searchAndNoResult" id="conversation-container" ref="comments" class="comment-content">
                <section id="comment-content-inner" :key="commentKey" class="comment-content-inner">
                    <article v-for="comment in selectedCase.comments" :key="comment.id" class="comment-container">
                        <section class="checkmark-container">
                            <Checkmark
                                v-if="showCheckboxes"
                                :key="'comment-' + comment.id + '-checked:' + commentIsChecked(comment.id)"
                                :hide="hideCheckmark(comment)"
                                :checked="commentIsChecked(comment.id)"
                                @input="(value) => setAttachChecked({ commentId: comment.id, value })"
                            />
                        </section>

                        <article class="comment-component-container">
                            <component
                                :is="getCommentComponent(comment.typeOfMessage)"
                                :item="comment"
                                :isSubcase="isSubcaseComment(comment)"
                            />
                        </article>
                    </article>
                </section>
            </section>

            <section
                v-if="searchAndNoResult"
                id="conversation-container"
                ref="comments"
                class="comment-content search-no-result"
            >
                <article class="no-comments">
                    <span>
                        {{ $t('caseComments.noCommentsTitle') }}
                    </span>
                    <span>
                        {{ $t('caseComments.noCommentsText') }}
                    </span>
                </article>
            </section>

            <CaseTravel v-if="showCaseTravel" :msgId="selectedCase?.case?.msgId" />
        </section>

        <!-- End of comments -->

        <!-- Footer -->
        <footer class="comment-tiptap">
            <section class="write-action-container">
                <primary-action-button
                    color="var(--v-primary-base)"
                    fontColor="white"
                    :hoverEffect="true"
                    @click="handleOpenTiptap"
                >
                    <div class="answer-action-button">
                        <v-icon size="16">mdi-pencil</v-icon>
                        <span>{{ $t('caseComments.write') }}</span>
                    </div>
                </primary-action-button>
            </section>

            <!-- Create Comments TippyTap -->

            <section v-show="showCreateComments" id="tiptap-container" :class="tiptapClass">
                <CreateComments :isEdited="false" :caseId="caseId" />
            </section>
        </footer>
    </section>

    <section
        v-else-if="caseState === states.ERROR || caseState === states.NOT_FOUND || !selectedCase"
        class="case-container-card case-container-card-error softShadow"
    >
        <div v-if="caseState === states.ERROR" class="case-card-error-container">
            <v-icon>mdi-alert-circle</v-icon>
            <span class="case-card-error-message">{{ $t('caseCard.error') }}</span>
        </div>

        <div v-else-if="caseState === states.NOT_FOUND" class="case-card-error-container">
            <v-icon>mdi-alert-circle</v-icon>
            <span class="case-card-error-message">{{ $t('caseCard.notFound') }}</span>
        </div>
    </section>
</template>
<script>
    import { mapGetters, mapState, mapActions } from 'vuex';
    import VueDragResize from 'vue-drag-resize';

    import { format } from 'date-fns';
    import config from '@/app/config';
    import { filterObjectsByKeys, getActiveFromSettings } from '@/utils';
    import CreateComments from '@/components/Cases/CreateComments.vue';
    import { states, caseTypes } from '@/enums/cases.enums';

    import Callback from '@/components/Cases/Callback/Callback.vue';
    import { getDay } from '@/utils/DateFormatter';
    import CaseCommentsTabs from '@/components/Cases/CaseComments/CaseCommentsTabs.vue';
    import CaseTravel from '@/components/Cases/CaseTravel/CaseTravel.vue';
    import { getTextColorByBackground } from '@/utils/Colors';
    import EditableText from '@/components/Global/EditableText.vue';
    import IntersectObserverWrapper from '@/components/Global/IntersectObserverWrapper.vue';
    import ChatGPT from '@/components/Global/ChatGPT/ChatGPT.vue';
    import Calls from '@/components/Cases/Calls/Calls.vue';
    import SocialCase from '@/components/Cases/Social/SocialCase.vue';
    import SocialCaseComment from '@/components/Cases/Social/SocialCaseComment.vue';
    import InternalType from '@/components/Cases/List/InternalType.vue';

    // *** Different Types of Comment Components ***
    import Email from '@/components/Cases/Comments/Email.vue';
    import System from '@/components/Cases/Comments/System.vue';
    import SubcaseComment from '@/components/Cases/Comments/Subcase.vue';
    import Internal from '@/components/Cases/Comments/Internal.vue';
    import Survey from '@/components/Cases/Comments/Survey.vue';
    import Chat from '@/components/Cases/Comments/Chat/Chat.vue';
    import SMS from '@/components/Cases/Comments/SMS.vue';
    import Form from '@/components/Cases/Comments/Form.vue';
    import Call from '@/components/Cases/Comments/Call.vue';
    import CallbackComment from '@/components/Cases/Comments/Callback.vue';
    import CallbackTry from '@/components/Cases/Comments/CallbackTry.vue';
    import ExternalSystem from '@/components/Cases/Comments/ExternalSystem.vue';
    import ActiveUsers from '@/components/Cases/ActiveUsers.vue';
    import CommonAvatar from '../../Global/CommonAvatar.vue';
    import CaseCommentsGeneric from './CaseCommentsGeneric.vue';
    import CaseIdChips from '../CaseIdChips.vue';
    import SubcaseList from '../Subcases/SubcaseList.vue';
    import InternalSubTypeIntegrationImage from './InternalSubTypeIntegrationImage.vue';

    import { statusTypes, channelTypes } from '../../../templates/cases.template';

    export default {
        name: 'CaseComments',
        components: {
            CaseCommentsTabs,
            CreateComments,
            Chat,
            Callback,
            FilePreview: () => import('@/components/Cases/CaseComments/FilePreview.vue'),
            ReadMore: () => import('@/components/Cases/CaseComments/ReadMore.vue'),
            HorizontalCaseMenu: () => import('@/components/Cases/Navigation/HorizontalCaseMenu/HorizontalCaseMenu.vue'),
            Checkmark: () => import('@/components/Global/Checkmark.vue'),
            AttachmentsMenu: () => import('@/components/Cases/CaseComments/AttachmentsMenu.vue'),
            VueDragResize,
            CaseTravel,
            EditableText,
            IntersectObserverWrapper,
            ChatGPT,
            Calls,
            SocialCase,
            SocialCaseComment,
            InternalType,
            CommonAvatar,
            CaseCommentsGeneric,
            Email,
            System,
            SubcaseComment,
            Internal,
            Survey,
            SMS,
            Form,
            Call,
            CallbackComment,
            CallbackTry,
            ExternalSystem,
            ActiveUsers,
            CaseIdChips,
            SubcaseList,
            InternalSubTypeIntegrationImage,
            SimpleTooltip: () => import('@/components/Global/SimpleTooltip.vue'),
        },
        props: {
            caseId: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                mediaUrl: config.mediaUrl,
                textFieldProps: {
                    solo: true,
                    flat: true,
                    hideDetails: true,
                },
                dateProps: {
                    headerColor: 'primary',
                },
                timeProps: {
                    format: '24hr',
                },
                cfg: config,
                clientList: [],
                selectedUser: [],
                items: [],
                caseContent: {},
                mergedCase: [],
                dialog: false,
                sla: '',
                status: '',
                timeOut: '',
                text: '',
                colors: this.$vuetify.theme.themes.light,
                statusObj: [],
                userObj: [],
                internalType: '',
                allFileTabs: [],
                tab: null,
                isMounted: false,
                toggleSignature: false,
                actionTriggered: false,
                states,
                loadingSearch: false,
                showSysNotif: false,
                commentKey: 0,
            };
        },
        computed: {
            ...mapState({
                caseState: (state) => state.Cases.caseState,
                clientNowOnCard: (state) => state.Cases.clientNowOnCard,
                channelConfig: (state) => state.System.userSettings.system.channels,
                statusTypesConfig: (state) => state.System.userSettings.system.statuses,
                caseTimeout: (state) => state.System.userSettings.cases.caseTimeout.value,
                selectedCaseId: (state) => state.Cases.selectedCase.case.caseId,
                userSettings: (state) => state.System.userSettings,
                stateView: (state) => state.Cases.commentView,
                showCcs: (state) => state.Comments.showCcs,
                cases: (state) => state.Cases.getCases,
                allUsers: (state) => state.Users.allUsers,
                loggedInUser: (state) => state.Auth.userObject,
                selectedCase: (state) => state.Cases.selectedCase,
                caseToLoad: (state) => state.Cases.caseToLoad,
                calls: (state) => state.Cases.caseCalls,
                tiptapState: (state) => state.Comments.tiptapState,
                commentSearch: (state) => state.Comments.commentSearch,
                attachChecked: (state) => state.Comments.attachChecked,
                showAttachCheckBoxes: (state) => state.Forwarder.showAttachCheckBoxes,
                reversedCommentsOrder: (state) => state.Comments.reversedCommentsOrder,
                systemEmails: (state) => state.Cases.systemEmails,
                userPreferences: (state) => state.Users.userPreferences,
                queues: (state) => state.Cases.caseFilters.queues,
            }),

            ...mapGetters({
                isReadOnly: 'Cases/isReadOnly',
                isReadSelectedCaseStateReadWrite: 'Layout/isReadSelectedCaseStateReadWrite',
                latestEmailComment: 'Comments/getLatestEmailComment',
                showCheckboxes: 'Comments/getShowCheckboxes',
                getLatestEmailComment: 'Comments/getLatestEmailComment',
            }),
            isOriginParent() {
                return this.selectedCase.case.originParent.caseId === this.selectedCase.case.caseId;
            },
            hasSubcases() {
                return this.selectedCase.subCases && this.selectedCase.subCases.length > 0;
            },
            isInSubCase() {
                return !!(this.isOriginParent && this.hasSubcases);
            },
            parentOrSubcase() {
                const caseData = this.selectedCase.case;
                if (!caseData.parent || caseData.parent === '') {
                    return 'parent';
                }
                return 'subcase';
            },
            showCreateComments() {
                return this.tiptapState !== 'closed';
            },
            tiptapClass() {
                return {
                    'tiptap-docked': this.tiptapState === 'open',
                    'tiptap-full': this.tiptapState === 'full',
                };
            },
            commentGridContainerStyle() {
                if (this.userSettings?.cases?.caseTabs?.active) {
                    return {
                        height: 'calc(100vh - 96px - 60px)',
                    };
                }
                return {
                    height: 'calc(100vh - 96px)',
                };
            },

            scrollToPixel() {
                if (this.reversedCommentsOrder) {
                    return this.$refs.comments.scrollHeight;
                }
                return 0;
            },

            showCaseTravel() {
                return (this.isCall || this.isCallback) && this.userSettings.cases.caseTravel.active;
            },

            showComments() {
                return (
                    this.caseState !== states.ERROR &&
                    this.caseState !== states.NOT_FOUND &&
                    this.caseState !== states.INIT &&
                    !this.caseIsNull
                );
            },

            searchAndNoResult() {
                return this.commentSearch && this.selectedCase?.comments?.length === 0;
            },

            caseIsNull() {
                return this.selectedCase == null;
            },

            statusTypes() {
                const formattedConfig = getActiveFromSettings(this.statusTypesConfig);
                return filterObjectsByKeys(formattedConfig, statusTypes, 'value');
            },
            timeoutMessage() {
                return `${this.$t('caseComments.detached')} ${this.caseTimeout} ${this.$t('caseComments.minutes')}`;
            },
            filteredChannelTypes() {
                const channelBooleans = getActiveFromSettings(this.channelConfig);
                return filterObjectsByKeys(channelBooleans, channelTypes, 'value');
            },

            loading() {
                return this.caseState === 'loading';
            },

            caseCommentsWidth: {
                get() {
                    return this.$store.state.Comments.caseCommentsWidth;
                },
                set(val) {
                    this.$store.commit('Comments/SET_CASE_COMMENTS_WIDTH', val);
                },
            },

            caseCommentsHeight: {
                get() {
                    return this.$store.state.Comments.caseCommentsHeight;
                },
                set(val) {
                    this.$store.commit('Comments/SET_CASE_COMMENTS_HEIGHT', val);
                },
            },

            getClientHeading() {
                if (!this.selectedCase?.case?.assignedTo) {
                    return '';
                }
                const { contactEmail } = this.selectedCase.case;

                return contactEmail;
            },
            getStatusTypes() {
                const statusTypesArray = [];
                for (let i = 0; i < this.statusTypes.length; i++) {
                    statusTypesArray[i] = {};
                    statusTypesArray[i].value = this.statusTypes[i].value;
                    statusTypesArray[i].Name = this.statusTypes[i].name;
                    statusTypesArray[i].icon = this.statusTypes[i].icon;
                    statusTypesArray[i].color = this.statusTypes[i].color;
                }
                return statusTypesArray;
            },

            selectedCase: {
                get() {
                    return this.$store.state.Cases.selectedCase;
                },
                set(value) {
                    this.setSelectedCase(value);
                },
            },

            getCaseTopic() {
                return this.selectedCase?.case?.topic || this.$t('caseComments.noTopic');
            },
            isChat() {
                return this.selectedCase?.case?.internalType === caseTypes.CHAT;
            },
            isCall() {
                return this.selectedCase?.case?.internalType === caseTypes.CALL;
            },
            isCallback() {
                return this.selectedCase?.case?.internalType === caseTypes.CALLBACK;
            },
        },

        watch: {
            caseState: {
                handler() {
                    if (this.showComments) {
                        // this doesnt get added?! cause of timing issue
                        this.removeScrollEvent();
                        this.addScrollEvent();
                    }
                },
            },
            caseToLoad: {
                handler() {
                    this.clearAttachChecked();
                    this.clearAllFiles();
                },
                immediate: true,
            },

            selectedCase: {
                handler(newCase, oldCase) {
                    this.$nextTick(() => {
                        this.fillContainer();
                        this.setShowAttachCheckBoxes(
                            this.userSettings.cases.attachConversationSettings?.attachConversation?.active
                        );
                        if (newCase?.case?.caseId !== oldCase?.case?.caseId) {
                            this.scrollToPos();
                        }
                        if (!newCase?.case) return;

                        this.getCaseSignature(newCase.case);

                        if (newCase?.case?.caseId !== oldCase?.case?.caseId) {
                            this.stampAction({ action: 'offCase', id: oldCase?.case?.caseId });
                            this.stampAction({ action: 'onCase', id: newCase?.case?.caseId });
                        }
                    });
                },
                immediate: true,
            },
            async dialog(newVal) {
                if (newVal !== true && this.clientList.length !== 0) {
                    return;
                }
                const payload = {
                    getAll: true,
                };
                this.clientList = await this.$store.dispatch('Cases/getAllClients', payload);
            },

            selectedCaseId: {
                immediate: true,
                handler(newCaseId, oldCaseId) {
                    this.handleCaseChange(newCaseId, oldCaseId);
                },
            },
            mergedCase(newVal) {
                this.$store.dispatch('Cases/setCaseData', newVal);
            },

            showSysNotif: {
                async handler() {
                    this.commentKey++;
                    if (!this.isMounted) return;

                    const settings = {
                        ...this.userPreferences,
                        showSystemNotifications: this.showSysNotif,
                    };

                    await this.setUserSettings({ userId: this.loggedInUser.userId, settings });
                    await this.getUserSettings(this.loggedInUser.userId);

                    await this.getCaseContent({
                        caseId: this.selectedCase.case.caseId,
                        showSystemNotifications: this.userPreferences.showSystemNotifications,
                    });
                },
                immediate: true,
            },
        },

        sockets: {
            connect() {
                this.caseBusy(this.selectedCaseId);
            },
        },

        async created() {
            this.setReversedCommentsOrder(this.userSettings?.cases?.reverseCommentsOrder?.active);

            this.inactivityTime();

            if (this.selectedCaseId) {
                this.caseBusy(this.selectedCaseId);
            }

            await this.getUserSettings(this.loggedInUser.userId);
            this.showSysNotif = this.userPreferences.showSystemNotifications;
        },

        beforeDestroy() {
            this.clearAttachChecked();
            this.resetTimersAfter();
            window.removeEventListener('resize', this.handleWidth);
            this.removeScrollEvent();
            this.stampAction({ action: 'offCase', id: this.caseId });
            this.resetSelectedCaseState();
            this.resetSelectedCaseFiles();
            this.resetSelectedCaseComments();
        },

        mounted() {
            window.addEventListener('resize', this.handleWidth);
            this.handleWidth();
            this.$nextTick(() => {
                this.isMounted = true;
                this.addScrollEvent();
                this.scrollToPos();
            });
        },
        methods: {
            ...mapActions({
                getMoreComments: 'Cases/getMoreComments',
                getCaseContent: 'Cases/getCaseContent',
                setCommentSearch: 'Comments/setCommentSearch',
                setAttachChecked: 'Comments/setAttachChecked',
                clearAttachChecked: 'Comments/clearAttachChecked',
                setShowAttachCheckBoxes: 'Forwarder/setShowAttachCheckBoxes',
                caseFree: 'Cases/caseFree',
                caseBusy: 'Cases/caseBusy',
                setReversedCommentsOrder: 'Comments/setReversedCommentsOrder',
                executeAnswerButton: 'Forwarder/executeAnswerButton',
                openTiptap: 'Comments/openTiptap',
                checkAllComments: 'Comments/checkAllComments',
                setEmailFrom: 'Comments/setEmailFrom',
                setEmailToRecipients: 'Comments/setEmailToRecipients',
                getCaseSignature: 'Cases/getCaseSignature',
                clearAllFiles: 'Files/clearAllFiles',
                getUserSettings: 'Users/getUserSettings',
                setUserSettings: 'Users/setUserSettings',

                resetSelectedCaseState: 'Cases/resetSelectedCaseState',
                resetSelectedCaseFiles: 'Files/resetState',
                resetSelectedCaseComments: 'Comments/resetState',
            }),
            stampAction(data) {
                const { action, id } = data;
                if (!id) return;
                this.$socket.emit(action, { id, userId: this.loggedInUser.userId });
            },
            handleCaseChange(newCaseId, oldCaseId) {
                if (oldCaseId === newCaseId) return;

                this.clearAttachChecked();
                this.caseFree(oldCaseId);
                this.setCommentSearch('');

                if (!newCaseId) return;

                this.caseBusy(newCaseId);
                this.handleCheckComments();

                if (this.tiptapState === 'closed') return;

                this.handleOpenTiptap();
            },
            handleCheckComments() {
                const attachCommentsByDefault =
                    this.userSettings.cases.attachConversationSettings?.attachConversation?.active;
                attachCommentsByDefault && this.checkAllComments();
            },
            handleOpenTiptap() {
                if (this.latestEmailComment) {
                    // If there is an email comment in the case
                    this.executeAnswerButton(this.latestEmailComment);
                } else if (this.systemEmails.length) {
                    // If there is no email comment in the case, we use contact email if there is one
                    const recipient = this.selectedCase?.case?.contactEmail
                        ? [this.selectedCase?.case?.contactEmail]
                        : [];
                    this.setEmailToRecipients(recipient);

                    /* Get the default sender email based on the current queue */
                    const queueId = this.selectedCase?.case?.queueId;
                    const currentQueue = this.queues.find((queue) => queue.ID === queueId);

                    this.setEmailFrom(currentQueue?.defaultSenderEmail || this.systemEmails[0]);
                    this.openTiptap();
                }
                this.openTiptap();
            },
            getChosenRecipient() {
                const { primaryEmail, secondaryEmail } = this.clientNowOnCard.data;
                return primaryEmail ? [primaryEmail] : secondaryEmail ? [secondaryEmail] : [];
            },
            scrollToPos() {
                this.$nextTick(() => {
                    const element = document.querySelector('#conversation-container');
                    if (!element) return;
                    if (this.reversedCommentsOrder) {
                        if (element) {
                            element.scrollTop = element.scrollHeight;
                            return;
                        }
                        return;
                    }
                    element.scrollTop = 0;
                });
            },

            setAttachConversationsInit() {
                this.setShowAttachCheckBoxes(
                    this.userSettings.cases.attachConversationSettings?.attachConversation?.active
                );
            },

            commentIsChecked(commentId) {
                return this.attachChecked.includes(commentId);
            },

            handleSearch(value) {
                this.loadingSearch = true;
                this.setCommentSearch(value);
                this.getCaseContent({
                    caseId: this.selectedCase.case.caseId,
                    search: value,
                    showSystemNotifications: this.userPreferences.showSystemNotifications,
                });
                this.loadingSearch = false;

                const targetDiv = document.querySelector('.comment-content');
                if (targetDiv) {
                    targetDiv.scrollTo({
                        top: this.scrollToPixel,
                        behavior: 'smooth',
                    });
                }
            },

            async fillContainer() {
                const container = this.$refs.comments;
                if (!container) return;
                const containerHeight = container.clientHeight;
                const contentHeight = container.scrollHeight;

                if (contentHeight < containerHeight) {
                    await this.getMoreComments({
                        caseId: this.selectedCase.case.caseId,
                        showSystemNotifications: this.userPreferences.showSystemNotifications,
                    });
                }
            },

            handleScroll() {
                const container = this.$refs.comments;
                let distanceFromBottom = 0;

                if (this.reversedCommentsOrder) {
                    distanceFromBottom = container.scrollTop;
                } else {
                    distanceFromBottom = container.scrollHeight - (container.scrollTop + container.clientHeight);
                }

                const DISTANCE_FROM_BUTTON = 600;

                const scrollStatement = this.reversedCommentsOrder
                    ? container.scrollTop === 0
                    : distanceFromBottom <= DISTANCE_FROM_BUTTON;

                if (scrollStatement && this.caseState !== 'loading' && this.selectedCase.hasMoreComments) {
                    if (!this.actionTriggered) {
                        this.actionTriggered = true;
                        this.getMoreComments({
                            caseId: this.selectedCase.case.caseId,
                            showSystemNotifications: this.userPreferences.showSystemNotifications,
                        });
                    }
                } else {
                    this.actionTriggered = false;
                }
            },

            getHeading() {
                return '';
            },

            addScrollEvent() {
                const scrollContainer = this.$refs.comments;
                if (!scrollContainer) {
                    return;
                }
                scrollContainer.addEventListener('scroll', this.handleScroll);
            },

            removeScrollEvent() {
                const scrollContainer = this.$refs.comments;
                if (!scrollContainer) {
                    return;
                }
                scrollContainer.removeEventListener('scroll', this.handleScroll);
            },

            updateTopic(value) {
                const payload = {
                    caseId: this.selectedCase.case.caseId,
                    data: {
                        topic: value,
                    },
                };
                this.$store.dispatch('Cases/updateCaseFields', payload);
                this.selectedCase.case.topic = value;
                this.editTopicMode = false;
            },

            getTextColorByBackground,

            getRecentTopic() {
                let subject = '';
                const topic = this.selectedCase?.case?.topic;
                if (this.selectedCase?.comments?.length > 0 && topic) {
                    const commentsLength = this.selectedCase.comments.length;
                    const lastCommentIsSameAsTopic =
                        this.selectedCase.comments[commentsLength - 1].subject === this.selectedCase?.case?.topic;
                    const lastCommentHasSubject = this.selectedCase.comments[commentsLength - 1].subject;

                    if (lastCommentIsSameAsTopic && lastCommentHasSubject) {
                        subject = `(${this.selectedCase.comments[commentsLength - 1].subject})`;
                    }
                }

                return subject;
            },

            hideCheckmark(item) {
                const showSystem = this.userSettings?.cases?.attachConversationSettings?.attachSystemMessages?.active;
                if (showSystem && item.typeOfMessage === 'system') {
                    return false;
                }

                const showInternalMessages =
                    this.userSettings?.cases?.attachConversationSettings?.attachInternalMessages?.active;

                if (showInternalMessages && item.typeOfMessage === 'internal') {
                    return false;
                }

                return item.typeOfMessage !== 'email';
            },

            handleWidth() {
                if (!this.$refs.comments) return;

                this.caseCommentsWidth = this.$refs.comments.clientWidth;
                this.caseCommentsHeight = this.$refs.comments.clientHeight;

                this.createCommentsWidth = this.caseCommentsWidth - 20;
            },

            closeCase() {
                if (this.caseState === states.LOADING) {
                    this.$toasted.show(this.$t('caseComments.waitUntilCaseIsLoaded'), {
                        icon: 'alert-circle',
                        type: 'warning',
                    });
                    return;
                }
                this.$router.push('/cases');
            },

            inactivityTime() {
                this.resetTimer(); // Initital run of timer
                document.addEventListener('mousemove', this.resetTimer);
                document.addEventListener('keypress', this.resetTimer);
            },

            resetTimersAfter() {
                document.removeEventListener('mousemove', this.resetTimer);
                document.removeEventListener('keypress', this.resetTimer);
                clearTimeout(this.timeOut);
                this.timeOut = null;
            },

            logout() {
                this.resetTimersAfter();
                this.$router.push('/cases');
                this.$toasted.show(
                    // 'Du har blivit utskickad från ärendet på grund av inaktivitet i mer än ' +
                    this.timeoutMessage,
                    {
                        icon: 'alert',
                        type: 'error',
                        duration: 60000,
                        closeOnSwipe: true,
                    }
                );
            },

            resetTimer() {
                clearTimeout(this.timeOut);
                this.timeOut = null;
                const timeoutMs = this.caseTimeout * 60000;
                this.timeOut = setTimeout(this.logout, timeoutMs);
            },

            getDay,
            format,

            getCommentComponent(commentType) {
                const commentTypeMap = {
                    email: Email,
                    system: System,
                    internal: Internal,
                    chat: Chat,
                    sms: SMS,
                    call: Call,
                    callback: CallbackComment,
                    'callback-attempt': CallbackTry,
                    social: SocialCaseComment,
                    survey: Survey,
                    'social-start': SocialCase,
                    'subcase-notice': SubcaseComment,
                    form: Form,
                    'external-system': ExternalSystem,
                };

                return commentTypeMap[commentType] || Email;
            },
            isSubcaseComment(comment) {
                const isSubcase = comment.parentCaseId !== undefined && comment.parentCaseId !== null;
                return isSubcase;
            },
        },
    };
</script>
<style lang="scss" scoped>
    .dynamic-truncate-content {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .settings-list-item {
        height: 48px;
    }

    .v-menu__content {
        box-shadow:
            rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
            rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important;
        padding: 0px !important;
    }

    .go-to-case-chip {
        display: flex;
        align-self: center;
        align-items: center;
        justify-content: center;
        background-color: var(--v-primary-lighten1);
        height: 100%;
        width: 70px;
        box-sizing: border-box;
        color: var(--v-primary-base);
        cursor: pointer;
        font-size: 11px;
        border-left: 1px white solid;
        border-right: 1px white solid;

        transition: transform 0.3s ease;

        &:hover {
            background-color: var(--v-primary-lighten1);
        }
    }
    .subcase-identifier {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        padding: 0px 32px;
        background-color: var(--v-primary-base);
        color: white;
        font-size: 12px;
    }

    .subcase-identifier-text {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .subcase-identifier-navigation {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        height: 35px;
        gap: 12px;
        padding: 0 10px;
    }
    .subcase-identifier-navigation > * {
        align-self: center;
        height: 100%;
        box-sizing: border-box;
    }
    .container-subcase-chip {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .chip-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        background-color: white;
        color: var(--v-primary-base);
        border: 1px var(--v-gray2-base) solid;
        cursor: pointer;
        height: 28px;
        padding: 0px 12px;
        border-radius: 16px;
    }
    .icon-branch-style {
        margin-right: 10px;
    }
    .case-topic {
        color: var(--v-gray4-base);
    }
    .comment-content-outer {
        display: flex;
        flex: 1;
        grid-template-columns: 1fr auto;
        overflow: hidden;
    }
    .active-users {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }
    .search-close {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
    }
    .comment-content-inner {
        padding: 32px;
        padding-bottom: 128px;
        display: grid;
        gap: 16px;
    }

    .no-comments {
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .no-comments > :first-child {
        font-size: 1.2rem;
        color: var(--v-gray4-base);
        text-align: center;
    }

    .no-comments > :nth-child(2) {
        font-size: 0.9rem;
        color: var(--v-gray2-base);
        text-align: center;
    }
    .search-no-result {
        align-items: center;
    }

    .tiptap-docked {
        bottom: 0;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        z-index: 10;
    }

    .tiptap-full {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90vw;
        height: 90vh;
        z-index: 99;
        background-color: transparent;
        max-width: 1400px;
    }

    .answer-action-button {
        display: grid;
        grid-template-columns: auto auto;
        gap: 4px;
    }

    .answer-action-button > * {
        align-self: center;
    }
    .write-action-container {
        position: absolute;
        right: 32px;
        bottom: 32px;
        z-index: 10;
    }

    .grayText2 {
        color: var(--v-gray2-base);
    }

    .grayText4 {
        color: var(--v-gray4-base);
    }

    .weightMedium {
        font-weight: 400 !important;
    }

    .smallText {
        font-size: 12px;
    }

    .bigText {
        font-size: 18px;
    }

    .c1-btn {
        z-index: 7;
    }

    :deep(.v-btn__content) {
        display: flex !important;
        flex-direction: column !important;
    }

    :deep(.images-rule img) {
        object-fit: contain;
        max-width: 100% !important;
        // important so images dont appear on the same line, and get fetched at the same time
        display: block;
    }

    /* new styles */

    .case-comment-header {
        display: grid;
        grid-template-columns: 1fr auto auto auto auto auto;
        padding: 8px 32px;
        width: 100%;
        gap: 8px;
        background-color: rgb(255, 255, 255);
    }

    .comment-component-container {
        display: grid;
    }

    .case-comment-header > * {
        align-self: center;
    }

    .case-comment-header > :first-child {
        justify-self: flex-start;
    }

    .case-comment-header > :last-child {
        justify-self: flex-end;
    }

    .case-comment-header-case-info {
        display: grid;
        grid-template-columns: auto auto auto auto;
        gap: 8px;
    }

    .case-comment-header-case-info > * {
        align-self: center;
    }

    .comment-grid-container {
        display: flex;
        flex-direction: column;
        border-radius: 16px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        position: relative;
        overflow: hidden;
    }

    .comment-header {
        background-color: white;
        display: grid;
        grid-template-rows: 1fr 1fr;
    }

    .comment-content {
        flex: 1;
        background-color: white;
        overflow: hidden;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        gap: 16px;
        box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.05);
    }

    .comment-tiptap {
        display: flex;
        flex-direction: column;

        background-color: white;
        border-radius: 4px;
        box-shadow:
            0px 8px 8px rgba(0, 0, 0, 0.022),
            0px 16px 16px rgba(0, 0, 0, 0.034);
        overflow: hidden;
    }

    .comment-container {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-rows: auto;
    }

    .checkmark-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .comment-has-more-comments {
        color: var(--v-gray2-base);
    }

    .case-container-card {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        height: 80vh;
        background-color: white;
        border-radius: 4px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    }

    .case-container-card-error {
        background-color: white;
        border-radius: 8px;
        padding-top: 64px;
        align-items: center;
    }

    .case-card-error-message {
        text-align: center;
        color: var(--v-gray2-base);
    }

    .case-card-error-container {
        max-width: 300px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
    }
</style>
<i18n lang="json">
{
    "en": {
        "caseComments": {
            "showSystemNotif": "Show system notifications",
            "closeCase": "Go out of case",
            "detached": "You have been dismissed from the case due to inactivity for more than",
            "minutes": "min:s",
            "waitUntilCaseIsLoaded": "Wait until the case is loaded before closing it",
            "write": "Write",
            "noCommentsTitle": "No results found.",
            "noCommentsText": "Try changing your search query.",
            "noTopic": "No topic",
            "commentSettings": "Comment settings",
            "subcaseFrom": "Related Case",
            "subcase": "Subcase",
            "amountOfSubcases": "Amount of related cases"
        },
        "caseCard": {
            "error": "Could not load the case, please try to reload the page",
            "notFound": "The case could not be found"
        }
    },
    "sv": {
        "caseComments": {
            "showSystemNotif": "Visa systemnotifikationer",
            "closeCase": "Gå ut ur ärendet",
            "detached": "Du har blivit utskickad från ärendet på grund av inaktivitet i mer än",
            "minutes": "min",
            "waitUntilCaseIsLoaded": "Vänta tills ärendet är laddat innan du stänger det",
            "write": "Skriv",
            "noCommentsTitle": "Inga resultat hittades.",
            "noCommentsText": "Försök att ändra din sökfråga.",
            "noTopic": "Inget ämne",
            "commentSettings": "Kommentar inställningar",
            "subcaseFrom": "Relaterat ärende",
            "subcase": "Underärende",
            "amountOfSubcases": "Antal relaterade ärenden"
        },

        "caseCard": {
            "error": "Kunde inte ladda ärendet, vänligen försök att ladda om sidan",
            "notFound": "Ärendet kunde inte hittas"
        }
    }
}
</i18n>
